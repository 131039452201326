<template>
  <div class="home">
    <div class="home_box">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
          <div class="clearfix home_1">
            <ul class="fl list_fl">
              <p>注册量({{RegNumber.totalRegNum?RegNumber.totalRegNum:'0'}})</p>
              <p v-if="showCountTag">查看历史投递简历数<span>{{RegNumber.totalRegNum?RegNumber.totalRegNum:'0'}}</span></p>
              <p><span>{{RegNumber.todayRegNum?RegNumber.todayRegNum:'0'}} / {{RegNumber.yesterdayRegNum?RegNumber.yesterdayRegNum:'0'}}</span>
                今天/昨天
              </p>
            </ul>
            <ul class="fr list_fr">
              <span class="el-icon-info"></span>
              <div class="m_top5" style="margin-top: 10px; height:60px; width:60px;border-radius: 75px 0 0 0">
                <img src="../../assets/img/index/img01.png" alt="">
              </div>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
          <div class="clearfix home_1">
            <ul class="fl list_fl" v-if="statisticalViews">
              <p>PC浏览量({{statisticalViews.cms_total? statisticalViews.cms_total: 0}})</p>
              <p><span>{{statisticalViews.cms_today_views? statisticalViews.cms_today_views: 0}} / {{statisticalViews.cms_yesterday_views?statisticalViews.cms_yesterday_views: 0}}</span>
                今天/昨天
              </p>
            </ul>
            <ul class="fr list_fr">
              <span class="el-icon-info"></span>
              <div class="m_top5" style="margin-top: 10px; height:60px; width:60px;border-radius: 75px 0 0 0">
                <img src="../../assets/img/index/img07.png" alt="">
              </div>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
          <div class="clearfix home_1">
            <ul class="fl list_fl">
              <p>移动端浏览量({{statisticalViews.wap_total ? statisticalViews.wap_total:'0'}})</p>
              <p><span>{{statisticalViews.wap_today_views? statisticalViews.wap_today_views: 0}} / {{statisticalViews.wap_yesterday_views?statisticalViews.wap_yesterday_views: 0}}</span>
                今天/昨天
              </p>
            </ul>
            <ul class="fr list_fr">
              <span class="el-icon-info"></span>
              <div class="m_top5" style="margin-top: 10px; height:60px; width:60px;border-radius: 75px 0 0 0">
                <img src="../../assets/img/index/img07.png" alt="">
              </div>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
          <div @click="toResume()" class="clearfix home_2">
            <ul  class="fl list_fl">
              <p>简历数({{resumeNum.totalResumeNum?resumeNum.totalResumeNum:'0'}})</p>
              <p v-if="showCountTag">查看昨日投递简历数<span>{{resumeNum.totalResumeNum?resumeNum.totalResumeNum:'0'}}</span></p>
              <p><span>{{resumeNum.todayResumeNum?resumeNum.todayResumeNum:'0'}} / {{resumeNum.yesterdayResumeNum?resumeNum.yesterdayResumeNum:'0'}}</span>
                今天/昨天
              </p>
            </ul>
            <ul class="fr list_fr">
              <span class="el-icon-info"></span>
              <div  class="m_top5" style="margin-top: 10px; height:60px; width:60px;border-radius: 75px 0 0 0">
                <img src="../../assets/img/index/img03.png" alt="">
              </div>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
          <div @click="toEnterpriseList()" class="clearfix home_3">
            <ul class="fl list_fl">
              <p>企业数({{entNum.totalEntNum?entNum.totalEntNum:'0'}})</p>
              <p v-if="showCountTag">共发布岗位数量<span>{{entNum.totalEntNum?entNum.totalEntNum:'0'}}</span></p>
              <p><span>{{entNum.todayEntNum?entNum.todayEntNum:'0'}} / {{entNum.yesterdayEntNum?entNum.yesterdayEntNum:'0'}}</span>
                今天/昨天
              </p>
            </ul>
            <ul class="fr list_fr">
              <span class="el-icon-info"></span>
              <div  class="m_top5" style="margin-top: 10px; height:60px; width:60px;border-radius: 75px 0 0 0">
                <img src="../../assets/img/index/img05.png" alt="">
              </div>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
          <div  @click="toJobs()"  class="clearfix home_4" >
            <ul class="fl list_fl" >
              <p >职位数({{jobNum.totalJobNum?jobNum.totalJobNum:'0'}})</p>
              <p v-if="showCountTag">查看昨日浏览的优秀人才<span>{{jobNum.totalJobNum?jobNum.totalJobNum:'0'}}</span></p>
              <p><span>{{jobNum.todayJobNum?jobNum.todayJobNum:'0'}} / {{jobNum.yesterdayJobNum?jobNum.yesterdayJobNum:'0'}}</span>
                今天/昨天
              </p>
            </ul>
            <ul class="fr list_fr">
              <span class="el-icon-info"></span>
              <div class="m_top5" style="margin-top: 10px; height:60px; width:60px;border-radius: 75px 0 0 0" >
                <img src="../../assets/img/index/img07.png" alt="">
              </div>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
          <div class="clearfix home_5">
            <ul class="fl list_fl">
              <p>简历下载量({{donwRes.totalResumDownloadNum?donwRes.totalResumDownloadNum:'0'}})</p>
              <p v-if="showCountTag">查看历史投递简历数<span>{{donwRes.totalResumDownloadNum?donwRes.totalResumDownloadNum:'0'}}</span></p>
              <p><span>{{donwRes.todayResumDownloadNum?donwRes.todayResumDownloadNum:'0'}} / {{donwRes.yesterdayResumDownloadNum?donwRes.yesterdayResumDownloadNum:'0'}}</span>
                今天/昨天
              </p>
            </ul>
            <ul class="fr list_fr">
              <span class="el-icon-info"></span>
              <div class="m_top5" style="margin-top: 10px; height:60px; width:60px;border-radius: 75px 0 0 0">
                <img src="../../assets/img/index/img02.png" alt="">
              </div>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
          <div class="clearfix home_6">
            <ul class="fl list_fl">
              <p>简历投递量({{UpRes.totalResumDownloadNum?UpRes.totalResumDownloadNum:'0'}})</p>
              <p v-if="showCountTag">查看历史投递简历数<span>{{UpRes.totalResumDownloadNum?UpRes.totalResumDownloadNum:'0'}}</span></p>
              <p><span>{{UpRes.todayResumDownloadNum?UpRes.todayResumDownloadNum:'0'}} / {{UpRes.yesterdayResumDownloadNum?UpRes.yesterdayResumDownloadNum:'0'}}</span>
                今天/昨天
              </p>
            </ul>
            <ul class="fr list_fr">
              <span class="el-icon-info"></span>
              <div class="m_top5" style="margin-top: 10px; height:60px; width:60px;border-radius: 75px 0 0 0">
                <img src="../../assets/img/index/img03.png" alt="">
              </div>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
          <div class="clearfix home_7">
            <ul class="fl list_fl">
              <p>面试邀请数({{InvitationNum.totalInterviewNum?InvitationNum.totalInterviewNum:'0'}})</p>
              <p v-if="showCountTag">共发布岗位数量<span>{{InvitationNum.totalInterviewNum?InvitationNum.totalInterviewNum:'0'}}</span></p>
              <p><span>{{InvitationNum.todayInterviewNum?InvitationNum.todayInterviewNum:'0'}} / {{InvitationNum.yesterdayInterviewNum?InvitationNum.yesterdayInterviewNum:'0'}}</span>
                今天/昨天
              </p>
            </ul>
            <ul class="fr list_fr">
              <span class="el-icon-info"></span>
              <div class="m_top5" style="margin-top: 10px; height:60px; width:60px;border-radius: 75px 0 0 0">
                <img src="../../assets/img/index/img06.png" alt="">
              </div>
            </ul>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
          <div class="clearfix home_8">
            <ul class="fl list_fl">
              <p>收藏简历量({{Collect.totalRCNum?Collect.totalRCNum:'0'}})</p>
              <p v-if="showCountTag">查看昨日浏览的优秀人才<span>{{Collect.totalRCNum?Collect.totalRCNum:'0'}}</span></p>
              <p><span>{{Collect.todayRCNum}} / {{Collect.yesterdayRCNum}}</span>
                今天/昨天
              </p>
            </ul>
            <ul class="fr list_fr">
              <span class="el-icon-info"></span>
              <div class="m_top5" style="margin-top: 10px; height:60px; width:60px;border-radius: 75px 0 0 0">
                <img src="../../assets/img/index/img08.png" alt="">
              </div>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="map_box m_top20" style="background: #f6f7f6;padding:15px">
      <el-row :gutter="30">
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" class="Home_border">
          <h2 class="title">就业地区统计</h2>
          <el-row class="el_row">
            <el-col :span='8' class="el_col">
              <div id="chart1"></div>
              <p>就业：{{regioninfo.cityIn}}人 <span class="m_fl15"></span>总：{{regioninfo.cityTotal}}人</p>
              <h2>区域内</h2>
            </el-col>
            <el-col :span='8' class="el_col">
              <div id="chart2"></div>
              <p>就业：{{regioninfo.cityOut}}人 <span class="m_fl15"></span>总：{{regioninfo.cityOutTotal}}人</p>
              <h2>区域外省内</h2>
            </el-col>
            <el-col :span='8' class="el_col">
              <div id="chart3"></div>
              <p>就业：{{regioninfo.province}}人 <span class="m_fl15"></span>总：{{regioninfo.provinceTotal}}人</p>
              <h2>省外</h2>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" class="Home_border">
          <h2 class="title">就业方式统计top6</h2>
          <div id="chart4"></div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" style="border-bottom: 2px solid #eee;">
          <h2 class="title">省外就业地区top5</h2>
          <div id="chart5"></div>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" class="Home_border">
          <h2 class="title">受欢迎岗位top5</h2>
          <div id="chart6"></div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" class="Home_border">
          <h2 class="title">岗位分类统计</h2>
          <div id="chart7"></div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" style="border-bottom: 2px solid #eee;">
          <div style="display: flex;justify-content: space-between;align-items: center;">
            <h2 class="title">岗位推荐趋势</h2>
            <el-select style="width:100px;margin-right:15px" size="mini" v-model="resumeType" @change="changeSumeType">
              <el-option label="半年" value="6"></el-option>
              <el-option label="一年" value="12"></el-option>
            </el-select>
          </div>
          <div id="chart8"></div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'home',
  data () {
    return {
      Type: 6,
      resumeType: '半年',

      RegNumber: {},//注册量
      resumeNum: {},//简历数
      entNum: {},//企业数
      jobNum: {},//职位数
      donwRes: {},//下载简历
      UpRes: {},//简历投递
      Collect: {},//收藏
      InvitationNum: {},//面试邀请
      statisticalViews: '',
      regioninfo: {},
      showCountTag: false
    }
  },
  created () {
    this.getRegData();
    this.getresumeData();
    this.getentNumData();
    this.getjobNumData();
    this.getInvitationNum();
    this.getUpResDAta();
    this.getdonwResDAta();
    this.getcollectResData();
  },
  mounted () {
    this.regionData();
    this.getStatisticalViews()
    this.getjobmodeData();
    this.jobprovinceOutData();
    this.getWelcomeJobData();
    this.getjobclassifyData();
    this.getjobRecomData();
  },
  methods: {
    toJobs:function () {
      this.$router.push({ path:'/Jobs'  })
    },
    toEnterpriseList:function () {
      this.$router.push({path:'/EnterpriseList'})
    },
    toResume:function () {
      this.$router.push({path:'/Resume'})
    },
    changeSumeType: function (e) {
      this.Type = e;
      this.getjobRecomData();
    },
    //区域统计
    regionData: function () {
      var _this = this;
      axios.get(this.API.statis.statisticalEmploymentArea, {
        headers: {
          token: this.Util.getjwtToken()
        },
        params: {
          provinceCode: ' 620000000000',
          cityCode: '620500000000'
        }
      }).then(function (res) {
        if (res.data) {
          if (res.data.success) {
            var temp = res.data.data;
            _this.regioninfo = temp;
            _this.$chart.regional('chart1', temp);
            _this.$chart.Outregion('chart2', temp);
            _this.$chart.provinceOut('chart3', temp);
          }
          // else{
          //    _this.$chart.regional('chart1',null);
          //    _this.$chart.Outregion('chart2',null);
          //    _this.$chart.provinceOut('chart3',null); 
          // }
        }
      })
    },
    //就业方式
    getjobmodeData: function () {
      var _this = this;
      axios.get(this.API.statis.statisticalEmploymentMode, {
        headers: {
          token: this.Util.getjwtToken()
        },
        params: {
          limit: 6
        }
      }).then(function (res) {
        if (res.data.success) {
          _this.$chart.jobmode('chart4', res.data.data);
        }

      })
    },
    //省外就业地区
    jobprovinceOutData: function () {
      var _this = this;
      axios.get(this.API.statis.statisticalEmployOutsideProvince, {
        headers: {
          token: this.Util.getjwtToken()
        },
        params: {
          limit: 5,
          provinceCode: '620000000000'
        }
      }).then(function (res) {
        if (res.data.success) {
          _this.$chart.jobprovinceOut('chart5', res.data.data);
        }

      })
    },
    //受欢迎的岗位
    getWelcomeJobData: function () {
      var _this = this;
      axios.get(this.API.statis.statisticalPopularPositions, {
        headers: {
          token: this.Util.getjwtToken()
        },
        params: {
          limit: 5,
          sortType: 1//1按申请排序，2按就业排序
        }
      }).then(function (res) {
        if (res.data.success) {
          _this.$chart.WelcomeJob('chart6', res.data.data);
        }

      })
    },
    //岗位分类统计
    getjobclassifyData: function () {
      var _this = this;
      axios.get(this.API.statis.statisticalJobClassification, {
        headers: {
          token: this.Util.getjwtToken()
        },
        params: {
          limit: 6
        }
      }).then(function (res) {
        if (res.data.success) {
          _this.$chart.jobclassify('chart7', res.data.data);
        }

      })
    },
    //岗位推荐
    getjobRecomData: function () {
      var _this = this;
      axios.get(this.API.statis.statisticalRecommendationTrend, {
        headers: {
          token: this.Util.getjwtToken()
        },
        params: {
          limit: this.Type,
        }
      }).then(function (res) {
        if (res.data.success) {
          _this.$chart.jobRecom('chart8', res.data.data);
        } else {
          _this.$chart.jobRecom('chart8', null);
        }

      })
    },
    // 注册量
    getRegData: function () {
      var _this = this;
      axios.get(this.API.statis.statisticalRegistration, {
        headers: {
          token: this.Util.getjwtToken()
        },
      }).then(function (res) {
        if (res.data.success) {
          _this.RegNumber = res.data.data

        }
      })
    },
    getStatisticalViews: function () {
      var _this = this;
      axios.get(this.API.statis.statisticalViews, {
        headers: {
          token: this.Util.getjwtToken()
        },
      }).then(function (res) {
        if (res.data.success) {
          _this.statisticalViews = res.data.data

        }
      })
    },
    // /ling/statis/statisticalViews
    //简历数
    getresumeData: function () {
      var _this = this;
      axios.get(this.API.statis.statisticalResume, {
        headers: {
          token: this.Util.getjwtToken()
        },
      }).then(function (res) {
        if (res.data.success) {
          _this.resumeNum = res.data.data
        }
      })
    },
    //企业数
    getentNumData: function () {
      var _this = this;
      axios.get(this.API.statis.statisticalEnterprise, {
        headers: {
          token: this.Util.getjwtToken()
        },
      }).then(function (res) {
        if (res.data.success) {
          _this.entNum = res.data.data
        }
      })
    },
    //职位数
    getjobNumData: function () {
      var _this = this;
      axios.get(this.API.statis.statisticalJobinfo, {
        headers: {
          token: this.Util.getjwtToken()
        },
      }).then(function (res) {
        if (res.data.success) {
          _this.jobNum = res.data.data
        }
      })
    },
    //简历下载数
    getdonwResDAta: function () {
      var _this = this;
      axios.get(this.API.statis.statisticalResumedownload, {
        headers: {
          token: this.Util.getjwtToken()
        },
      }).then(function (res) {
        if (res.data) {
          if (res.data.success) {
            _this.donwRes = res.data.data
            _this.donwRes.totalResumDownloadNum = _this.donwRes.totalResumDownloadNum.substring(0, 17);
          }
        }
      })
    },
    //简历投递数
    getUpResDAta: function () {
      var _this = this;
      axios.get(this.API.statis.statisticalResumeDelivery, {
        headers: {
          token: this.Util.getjwtToken()
        },
      }).then(function (res) {
        if (res.data) {
          if (res.data.success) {
            _this.UpRes = res.data.data
          }
        }
      })
    },
    //面试邀请
    getInvitationNum: function () {
      var _this = this;
      axios.get(this.API.statis.statisticalInterviewInvitation, {
        headers: {
          token: this.Util.getjwtToken()
        },
      }).then(function (res) {
        if (res.data) {
          if (res.data.success) {
            _this.InvitationNum = res.data.data
          }
        }
      })
    },
    //收藏
    getcollectResData: function () {
      var _this = this;
      axios.get(this.API.statis.statisticalCollectResume, {
        headers: {
          token: this.Util.getjwtToken()
        },
      }).then(function (res) {
        if (res.data) {
          if (res.data.success) {
            _this.Collect = res.data.data
          }
        }
      })
    }
  }

}
</script>
<style scoped lang="scss">
#chart1,
#chart3,
#chart2 {
  width: 100%;
  height: 180px;
}
#chart4,
#chart5,
#chart6,
#chart7,
#chart8 {
  width: 100%;
  height: 250px;
}
.home_1,
.home_2,
.home_3,
.home_4,
.home_5,
.home_6,
.home_7,
.home_8 {
  padding: 0 15px;
  margin-top: 15px;
}
.home_1 {
  border: 1px solid #f3f7ff;
}
.home_2 {
  border: 1px solid #fff4dc;
}
.home_3 {
  border: 1px solid #dbf4d2;
}
.home_4 {
  border: 1px solid #f4e5fe;
}
.home_5 {
  border: 1px solid #f3f7ff;
}
.home_6 {
  border: 1px solid #fff4dc;
}
.home_7 {
  border: 1px solid #dbf4d2;
}
.home_8 {
  border: 1px solid #f4e5fe;
}
</style>